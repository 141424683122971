import {defineStore} from "pinia";
import {useLocalStorage} from "@vueuse/core";
import {
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_CANCELLED,
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_PAYMENT_RECEIVED,
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_PAYMENT_REQUESTED,
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_SIGNUP_COMPLETED,
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_STARTED
} from "~/store/constants/membership";

export interface MembershipSignupProcess {
    accountId: string,
    signupProcessId: string,
    status?: number
}

interface State {
    membershipSignupProcessIds: Array<MembershipSignupProcess>
}

export const useMembershipStore = defineStore('membership', {
    state: (): State => ({
        // @ts-ignore
        membershipSignupProcessIds: useLocalStorage('pinia/membership/membershipSignupProcessIds', [])
    }),
    actions: {
        hasActiveSignupProcess(accountId: string) {
            return this.membershipSignupProcessIds.some(
                (signupProcess) => signupProcess.accountId === accountId && signupProcess.status &&
                    ![MEMBERSHIP_SIGNUP_PROCESS_STATUS_SIGNUP_COMPLETED, MEMBERSHIP_SIGNUP_PROCESS_STATUS_CANCELLED].includes(signupProcess.status))
        },

        getSignupProcessIdForAccount(accountId: string) {
            return this.membershipSignupProcessIds.find(
                (signupProcess) => signupProcess.accountId === accountId
            )
        },

        removeSignupProcessIdForAccount(accountId: string) {
            this.membershipSignupProcessIds = this.membershipSignupProcessIds.filter(
                (signupProcess) => signupProcess.accountId !== accountId
            )
        },

        replaceMembershipSignupProcess(newObject: MembershipSignupProcess) {
            this.membershipSignupProcessIds = this.membershipSignupProcessIds.map(
                (signupProcess) => {
                    if (signupProcess.accountId === newObject.accountId) {
                        return newObject
                    }
                    return signupProcess
                }
            )
        },

        updateMembershipSignupProcessStatusForId(signupProcessId: string, newStatus: number) {
            this.membershipSignupProcessIds = this.membershipSignupProcessIds.map(
                (signupProcess) => {
                    if (signupProcess.signupProcessId === signupProcessId) {
                        return {
                            ...signupProcess,
                            status: newStatus
                        }
                    }
                    return signupProcess
                }
            )
        }
    }
})