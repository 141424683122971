import {MembershipApi} from "~/gen/openapi/portalService";
import {useMutation, useQuery} from "@tanstack/vue-query";
import type { CreateSignupProcessDto } from "~/gen/openapi/portalService";
import {type MembershipSignupProcess, useMembershipStore} from "~/stores/membership";
import {
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_CANCELLED, MEMBERSHIP_SIGNUP_PROCESS_STATUS_PAYMENT_REQUESTED,
    MEMBERSHIP_SIGNUP_PROCESS_STATUS_SIGNUP_COMPLETED
} from "~/store/constants/membership";


export const useMembershipService = () => {
    const {$axios, $config} = useNuxtApp()
    const membershipApi = new MembershipApi(undefined, $config.public.apiBaseHost, $axios)
    const membershipStore = useMembershipStore()

    const signupProcessStatusQuery = (accountId: string, signupProcessId?: string) => useQuery({
        queryKey: ['membershipGetSignupProcessStatus', signupProcessId],
        queryFn: async () => {
            if (signupProcessId) {
                return (await membershipApi.membershipGetSignupProcessStatus(signupProcessId)).data
            } return {}
        },
        staleTime: 10 * 1000,
        enabled: computed(() => {
            const status = membershipStore.getSignupProcessIdForAccount(accountId)?.status
            if (!status) {
                return true
            }
            return ![MEMBERSHIP_SIGNUP_PROCESS_STATUS_SIGNUP_COMPLETED, MEMBERSHIP_SIGNUP_PROCESS_STATUS_CANCELLED].includes(status)
        }),
        refetchInterval: 10 * 1000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: true
    })

    const { isPending: isPendingCreateSignupProcess, error: createSignupProcessError, isError: errorCreatingSignupProcess,  isSuccess: createSignupProcessSuccess, mutate: createSignupProcess} = useMutation({
        mutationFn: ({AccountId, CallbackUrl}: CreateSignupProcessDto) => {
            membershipStore.membershipSignupProcessIds.push({accountId: AccountId!, signupProcessId: '', status: MEMBERSHIP_SIGNUP_PROCESS_STATUS_PAYMENT_REQUESTED})
            return membershipApi.membershipCreateSignupProcess({AccountId, CallbackUrl})
        },
        onSuccess(response, variables) {
            if (response.data.SignupProcessId) {
                const existingSignupProcess = membershipStore.getSignupProcessIdForAccount(variables.AccountId!)
                const newSignupProcess = {
                    ...existingSignupProcess,
                    signupProcessId: response.data.SignupProcessId,
                } as MembershipSignupProcess
                membershipStore.replaceMembershipSignupProcess(newSignupProcess)
                window.open(response.data.VippsRedirectUrl!, "_self")
            } else {
                console.error("MembershipCreateSignupProcess returned empty SignupProcessId")
            }
        },
        onError(error, variables, context) {
            membershipStore.removeSignupProcessIdForAccount(variables.AccountId!)
            console.error(error)
        },
    })

    return {
        signupProcessStatusQuery,
        isPendingCreateSignupProcess,
        createSignupProcessError,
        errorCreatingSignupProcess,
        createSignupProcessSuccess,
        createSignupProcess
    }
}