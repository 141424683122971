/* tslint:disable */
/* eslint-disable */
/**
 * Portal API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateSignupProcessDto } from '../model';
// @ts-ignore
import { CreateSignupProcessResult } from '../model';
// @ts-ignore
import { SignupProcessInformation } from '../model';
/**
 * MembershipApi - axios parameter creator
 * @export
 */
export const MembershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create signup process with a specified callback url.  An id to a new startup process will be returned, which can be used  to poll the status of the payment and signup process.
         * @param {CreateSignupProcessDto} [createSignupProcessDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membershipCreateSignupProcess: async (createSignupProcessDto?: CreateSignupProcessDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/signupProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSignupProcessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get signup process status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membershipGetSignupProcessStatus: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('membershipGetSignupProcessStatus', 'id', id)
            const localVarPath = `/api/v1/membership/signupProcess/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembershipApi - functional programming interface
 * @export
 */
export const MembershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create signup process with a specified callback url.  An id to a new startup process will be returned, which can be used  to poll the status of the payment and signup process.
         * @param {CreateSignupProcessDto} [createSignupProcessDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async membershipCreateSignupProcess(createSignupProcessDto?: CreateSignupProcessDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSignupProcessResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.membershipCreateSignupProcess(createSignupProcessDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MembershipApi.membershipCreateSignupProcess']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get signup process status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async membershipGetSignupProcessStatus(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupProcessInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.membershipGetSignupProcessStatus(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MembershipApi.membershipGetSignupProcessStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MembershipApi - factory interface
 * @export
 */
export const MembershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembershipApiFp(configuration)
    return {
        /**
         * 
         * @summary Create signup process with a specified callback url.  An id to a new startup process will be returned, which can be used  to poll the status of the payment and signup process.
         * @param {CreateSignupProcessDto} [createSignupProcessDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membershipCreateSignupProcess(createSignupProcessDto?: CreateSignupProcessDto, options?: any): AxiosPromise<CreateSignupProcessResult> {
            return localVarFp.membershipCreateSignupProcess(createSignupProcessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get signup process status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membershipGetSignupProcessStatus(id: string, options?: any): AxiosPromise<SignupProcessInformation> {
            return localVarFp.membershipGetSignupProcessStatus(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembershipApi - object-oriented interface
 * @export
 * @class MembershipApi
 * @extends {BaseAPI}
 */
export class MembershipApi extends BaseAPI {
    /**
     * 
     * @summary Create signup process with a specified callback url.  An id to a new startup process will be returned, which can be used  to poll the status of the payment and signup process.
     * @param {CreateSignupProcessDto} [createSignupProcessDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public membershipCreateSignupProcess(createSignupProcessDto?: CreateSignupProcessDto, options?: RawAxiosRequestConfig) {
        return MembershipApiFp(this.configuration).membershipCreateSignupProcess(createSignupProcessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get signup process status
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public membershipGetSignupProcessStatus(id: string, options?: RawAxiosRequestConfig) {
        return MembershipApiFp(this.configuration).membershipGetSignupProcessStatus(id, options).then((request) => request(this.axios, this.basePath));
    }
}

